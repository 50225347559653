import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import { colors } from '../../styles/atoms/colors';
import { font } from '../../styles/atoms/typography';

export const SimpleFilterStyle = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  width: 100%;

  .filterHeader {
    ${font('text', 'xl', '700')}
  }

  .MuiSelect-select svg {
    display: none;
  }
`;

export const FilterItem = styled(Link)`
  display: flex;
  ${props =>
    props.selected ? font('text', 'xl', '700') : font('text', 'xl', '400')};
  color: ${props => (props.selected ? colors.primary[500] : colors.gray[900])};
  padding: 16px 24px;
  border-radius: 8px;
  transition: color 0.2s ease-in-out, background-color 0.3s ease-in-out;
  background-color: ${props =>
    props.selected ? colors.primary[50] : 'transparent'};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${colors.primary[500]};
    background-color: ${colors.primary[50]};
    text-decoration: none;
  }
`;

export const DropdownItem = styled(MenuItem)`
  && {
    justify-content: space-between;
    padding: 24px;

    svg {
      color: ${colors.primary[500]};
    }

    &:not(.Mui-selected) svg {
      display: none;
    }

    &.Mui-selected {
      background-color: ${colors.base.heroSurface};
      ${font('text', 'lg', '700')}
    }
  }
`;
